import React from 'react'
import './exit.css';
const exit = () => {
  return (
    <div className="exit-card">
   <h1 className='para'>Your Recuriter Will be in Touch With You</h1>
   <h1 className='thank'>Thank you for taking the assesment</h1>
   <button className='button1'>Back To Home</button>
    </div>
  )
}

export default exit
